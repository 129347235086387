import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import data from "../DataList.json";
import { Link } from "react-router-dom";

export default function Search({ placeholder }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.text.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <div className="search-container">
      <div className="d-flex flex-row">
        <input
          type="search"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
          aria-label="Search"
          className="p-1"
        />
        <div className="search-icon ps-2 pe-2">
          {filteredData.length === 0 ? (
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          ) : (
            <FontAwesomeIcon
              icon={faXmark}
              id="clearBtn"
              onClick={clearInput}
            />
          )}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="search-result">
          {filteredData.slice(0, 15).map((value) => {
            return (
              <Link to={value.link} key={value.id}>
                <p className="p-1">{value.text}</p>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}
