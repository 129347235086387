import { Container, Image, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";

//Images
import cmslogo from "../assets/images/schoolIcons/cmsd-logo-75.png";

const footerLinks = [
  { id: 1, to: "/districtmain/calendar", text: "Calendar" },
  {
    id: 2,
    to: "/districtmain/staffdirectory",
    text: "District Staff Directory",
  },
  { id: 3, to: "/employment", text: "Employment" },
  { id: 4, to: "/districtmain/mapsdirections", text: "Map & Directions" },
  { id: 5, href: "https://www.parentsquare.com/districts/1619", text: "News" },
  {
    id: 6,
    to: "/districtmain/nondiscrimination",
    text: "Notice of Non-Discrimination",
  },
  { id: 7, to: "/districtmain/sitemap", text: "Site Map" },
  { id: 8, to: "/districtmain/staffquicklinks", text: "Staff Quick Links" },
];

function Footer() {
  return (
    <>
      <footer>
        <Container fluid className="footer-container">
          <Row>
            {/* Logo Section */}
            <Col
              xs={12}
              sm={4}
              md={3}
              lg={1}
              className="footer-logo order-md-1"
            >
              <Image
                src={cmslogo}
                className="footer-cms-logo"
                alt="Carlsbad Municipal School District logo"
                width={75}
                height={75}
              />
            </Col>

            {/* Address Section */}
            <Col
              xs={12}
              sm={8}
              md={9}
              lg={3}
              className="footer-address order-md-2"
            >
              <address>
                <ul>
                  <li>CARLSBAD MUNICIPAL SCHOOL DISTRICT</li>
                  <li>408 N. Canyon St.</li>
                  <li>Carlsbad, NM 88220</li>
                  <li>
                    Phone:{" "}
                    <a
                      href="tel:5752343300"
                      aria-label="Call us at (575) 234-3300"
                    >
                      (575) 234-3300
                    </a>
                  </li>
                  <li>
                    Fax:{" "}
                    <a
                      href="fax:5752343367"
                      aria-label="Fax us at (575) 234-3367"
                    >
                      (575) 234-3367
                    </a>
                  </li>
                </ul>
              </address>
            </Col>

            {/* Footer Links */}
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="footer-links order-md-3"
            >
              <nav aria-labelledby="footer-navigation">
                <h2 id="footer-navigation" className="sr-only">
                  Footer Navigation
                </h2>
                <Row>
                  {footerLinks.map((link, index) => (
                    <Col xs={12} sm={6} key={link.id}>
                      {link.href ? (
                        <a
                          href={link.href}
                          className="d-block mb-2"
                          target="_blank"
                          rel="noreferrer noopener"
                          aria-label={`External link to ${link.text}`}
                        >
                          {link.text}
                        </a>
                      ) : (
                        <Link
                          to={link.to}
                          className="d-block mb-2"
                          aria-label={`Internal link to ${link.text}`}
                        >
                          {link.text}
                        </Link>
                      )}
                    </Col>
                  ))}
                </Row>
              </nav>
            </Col>

            {/* Social Media Links */}
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={1}
              className="footer-social-media order-md-4"
            >
              <div aria-label="Social Media Links" className="icons">
                <a
                  href="https://www.facebook.com/CMSDK12"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Visit our Facebook page"
                  className="icon facebook"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </div>
              <div>
                <a
                  href="https://twitter.com/carlsbadmsd"
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label="Visit our Twitter page"
                  className="icon twitter"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
