import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Navbar,
  Nav,
  Image,
  Dropdown,
  Button,
} from "react-bootstrap";
import Search from "../components/Search.js";
import datalist from "../DataList.json";
import cmslogo from "../assets/images/schoolIcons/cmsd-logo-75.png";

export default function Header() {
  const navLinks = [
    { to: "/", label: "Home" },
    { to: "/about", label: "About" },
    { to: "/employment", label: "Employment" },
    { to: "/media", label: "Media" },
    // { to: "/cmsdtheaters", label: "CMSD Theaters" },
  ];

  const dropdownMenus = [
    {
      id: "dropdown-schools",
      title: "Schools",
      items: [
        { to: "/schools", label: "All Schools" },
        { to: "/schools/chs/home", label: "Carlsbad High School" },
        {
          to: "/schools/cechs/home",
          label: "Carlsbad Early College High School",
        },
        { to: "/schools/cec/home", label: "Carlsbad Enrichment Center" },
        { to: "/schools/cisav/home", label: "CIS Alta Vista" },
        { to: "/schools/cispr/home", label: "CIS P.R. Leyva" },
        { to: "/schools/cottonwood/home", label: "Cottonwood" },
        { to: "/schools/desertwillow/home", label: "Desert Willow" },
        { to: "/schools/monterrey/home", label: "Monterrey" },
        { to: "/schools/ocotillo/home", label: "Ocotillo" },
        { to: "/schools/riverside/home", label: "Riverside" },
        { to: "/schools/sunset/home", label: "Sunset" },
        {
          to: "/schools/ecec/home",
          label: "Early Childhood Education Center",
        },
      ],
    },
    {
      id: "dropdown-departments",
      title: "Departments",
      items: [
        { to: "/departments", label: "All Departments" },
        { to: "/departments/admin", label: "Administration" },
        { to: "/departments/athletics", label: "Athletics" },
        { to: "/departments/capitalprojects", label: "Capital Projects" },
        { to: "/departments/federalprograms", label: "Federal Programs" },
        { to: "/departments/finance", label: "Finance" },
        { to: "/departments/humanresources", label: "Human Resources" },
        { to: "/departments/instruction", label: "Instruction" },
        { to: "/departments/nutrition", label: "Nutrition and Food Services" },
        { to: "/departments/operations", label: "Operations" },
        { to: "/departments/purchasing", label: "Purchasing" },
        { to: "/departments/specialservices", label: "Special Services" },
        { to: "/departments/technology", label: "Technology" },
      ],
    },
    {
      id: "dropdown-programs",
      title: "Programs",
      items: [
        { to: "/programs/opportunityculture", label: "Opportunity Culture" },
        { to: "/programs/kidsreadnow", label: "Kids Read Now" },
        { to: "/programs/crisisresponse", label: "Crisis Response" },
      ],
    },
  ];

  return (
    <div className="top-navigation">
      <Row className="top-logo-motto">
        <Col xs="auto">
          <Link to="/">
            <Image src={cmslogo} className="p-2" alt="CMSD logo" />
          </Link>
        </Col>
        <Col xs="auto">
          <div className="top-nav-motto">
            <p>CARLSBAD MUNICIPAL SCHOOL DISTRICT</p>
            <p>Excellence Today, Opportunity Tomorrow</p>
          </div>
        </Col>
      </Row>

      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="navbar">
          <Nav>
            {navLinks.map(({ to, label }, index) => (
              <Button
                key={index}
                as={Link}
                to={to}
                className="btn-sq"
                aria-label={label}
                target="_blank"
                rel="noreferrer noopener"
              >
                {label}
              </Button>
            ))}
            {dropdownMenus.map(({ id, title, items }) => (
              <Dropdown key={id}>
                <Dropdown.Toggle id={id}>{title}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {items.map(({ to, label }, index) => (
                    <Dropdown.Item
                      key={index}
                      as={Link}
                      to={to}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ))}
          </Nav>
          <div>
            <Search placeholder="Search" data={datalist} />
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
