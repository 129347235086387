// Global Imports
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// Import custom CSS
import "../src/assets/styles/critical.css";
import "../src/assets/styles/global.css";

// Main Page Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import GoogleTranslate from "./components/GoogleTranslate";

// Lazy Load Utility
const lazyLoad = (path) => lazy(() => import(`./pages/${path}`));

// Page Imports
const pages = {
  home: lazyLoad("Home"),
  about: lazyLoad("About"),
  schools: lazyLoad("Schools"),
  departments: lazyLoad("Departments"),
  programs: lazyLoad("Programs"),
  media: lazyLoad("Media"),
  employment: lazyLoad("Employment"),
};

// Department Routes
const departmentRoutes = [
  { path: "admin", component: lazyLoad("departments/Admin") },
  { path: "athletics", component: lazyLoad("departments/Athletics") },
  { path: "purchasing", component: lazyLoad("departments/Purchasing") },
  { path: "humanresources", component: lazyLoad("departments/HumanResources") },
  { path: "technology", component: lazyLoad("departments/Technology") },
  { path: "operations", component: lazyLoad("departments/Operations") },
  { path: "nutrition", component: lazyLoad("departments/Nutrition") },
  { path: "instruction", component: lazyLoad("departments/Instruction") },
  {
    path: "specialservices",
    component: lazyLoad("departments/SpecialServices"),
  },
  {
    path: "federalprograms",
    component: lazyLoad("departments/FederalPrograms"),
  },
  { path: "finance", component: lazyLoad("departments/Finance") },
  {
    path: "capitalprojects",
    component: lazyLoad("departments/CapitalProjects"),
  },
];

// Program Routes
const programRoutes = [
  {
    path: "opportunityculture",
    component: lazyLoad("programs/OpportunityCulture"),
  },
  { path: "kidsreadnow", component: lazyLoad("programs/KidsReadNow") },
  { path: "crisisresponse", component: lazyLoad("programs/CrisisResponse") },
];

// District Links
const districtLinks = [
  {
    path: "/districtmain/antiracism",
    component: lazyLoad("districtmain/Antiracism"),
  },
  {
    path: "/districtmain/anonalerts",
    component: lazyLoad("districtmain/AnonAlerts"),
  },
  {
    path: "/districtmain/calendar",
    component: lazyLoad("districtmain/Calendar"),
  },
  {
    path: "/districtmain/schoolboard",
    component: lazyLoad("districtmain/Schoolboard"),
  },
  {
    path: "/about/superintendent",
    component: lazyLoad("about/Superintendent"),
  },
  {
    path: "/districtmain/sponsors",
    component: lazyLoad("districtmain/Sponsors"),
  },
  {
    path: "/districtmain/lunchmenu",
    component: lazyLoad("districtmain/LunchMenu"),
  },
  {
    path: "/districtmain/parentsstudents",
    component: lazyLoad("districtmain/ParentsStudents"),
  },
  {
    path: "/districtmain/iprarequest",
    component: lazyLoad("about/IPRARequest"),
  },
  { path: "/districtmain/titleix", component: lazyLoad("about/TitleIX") },
  { path: "/districtmain/message", component: lazyLoad("about/Message") },
  {
    path: "/districtmain/staffquicklinks",
    component: lazyLoad("districtmain/StaffQuickLinks"),
  },
  {
    path: "/districtmain/nondiscrimination",
    component: lazyLoad("districtmain/Nondiscrimination"),
  },
  {
    path: "/districtmain/staffdirectory",
    component: lazyLoad("districtmain/StaffDirectory"),
  },
  {
    path: "/districtmain/sitemap",
    component: lazyLoad("districtmain/SiteMap"),
  },
  {
    path: "/districtmain/bullypolicy",
    component: lazyLoad("about/BullyPolicy"),
  },
  { path: "/about/aiinformation", component: lazyLoad("about/AIInformation") },
  {
    path: "/districtmain/mapsdirections",
    component: lazyLoad("districtmain/MapsDirections"),
  },
];

// School Imports
const schoolImports = {
  CISAV: ["Home", "About", "Directory", "AvBellSchedule"],
  CISPR: ["Home", "About", "Directory", "PrBellSchedule"],
  CHS: ["Home", "About", "Directory", "BellSchedule"],
  CECHS: ["Home", "About", "Directory", "CechsBellSchedule"],
  CEC: ["Home", "About", "Directory", "CecBellSchedule"],
  Cottonwood: ["Home", "About", "Directory", "CwBellSchedule"],
  DesertWillow: ["Home", "About", "Directory", "DwBellSchedule"],
  Monterrey: ["Home", "About", "Directory", "MtBellSchedule"],
  Ocotillo: ["Home", "About", "Directory", "OtBellSchedule"],
  Riverside: ["Home", "About", "Directory", "RsBellSchedule"],
  Sunset: ["Home", "About", "Directory", "SsBellSchedule"],
  ECEC: ["Home", "About", "Directory", "ECECBellSchedule"],
};

const generateSchoolRoutes = (schoolImports) =>
  Object.entries(schoolImports).flatMap(([schoolName, routes]) =>
    routes.map((route) => (
      <Route
        key={`${schoolName}-${route}`}
        path={`/schools/${schoolName.toLowerCase()}/${route.toLowerCase()}`}
        element={React.createElement(
          lazy(() => import(`./pages/schools/${schoolName}/${route}`))
        )}
      />
    ))
  );

export default function App() {
  return (
    <>
      <Header />
      <GoogleTranslate />
      <div className="container">
        <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
          <Routes>
            {/* Main Routes */}
            <Route path="/" element={<pages.home />} />
            <Route path="/about" element={<pages.about />} />
            <Route path="/schools" element={<pages.schools />} />
            <Route path="/departments" element={<pages.departments />} />
            <Route path="/programs" element={<pages.programs />} />
            <Route path="/media" element={<pages.media />} />
            <Route path="/employment" element={<pages.employment />} />

            {/* Department Routes */}
            {departmentRoutes.map(({ path, component }) => (
              <Route
                key={path}
                path={`/departments/${path}`}
                element={React.createElement(component)}
              />
            ))}

            {/* Program Routes */}
            {programRoutes.map(({ path, component }) => (
              <Route
                key={path}
                path={`/programs/${path}`}
                element={React.createElement(component)}
              />
            ))}

            {/* District Links */}
            {districtLinks.map(({ path, component }) => (
              <Route
                key={path}
                path={path}
                element={React.createElement(component)}
              />
            ))}

            {/* School Routes */}
            {generateSchoolRoutes(schoolImports)}
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </>
  );
}
